<template>
  <div class="modal fade" id="modal-default" ref="productionPlanListModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">สร้างแผนผลิตใหม่</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Loading v-if="isLoading"/>
        <form @submit.prevent="submitForm">
          <div class="modal-body">
            <div class="card card-primary">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-12">
                    <b>ทั่วไป</b>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">หมายเลข Work Order</span><br>
                    <span class="text-bold">{{ workOrderData.woNumber }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่องาน</span><br>
                    <span class="text-bold front-color-custom">{{ workOrderData.woWcode }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ชื่อลูกค้า</span><br>
                    <span class="text-bold">{{ workOrderData.ctmName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">ประเภทงาน</span><br>
                    <span class="text-bold">{{ workOrderData.wotName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">กำหนดส่งงาน</span><br>
                    <span class="text-bold">{{ workOrderData.woDeliverDate }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-4">
                    <span class="inActiveText">พนักงานขาย</span><br>
                    <span class="text-bold">{{ workOrderData.empName }}</span>
                  </div>
                  <div style="padding-left: 3%" class="col-sm-12">
                    <span class="inActiveText">หมายเหตุ</span><br>
                    <span class="text-bold">{{ workOrderData.woRemark }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-12 mb-2 bg-secondary bg-light">
              <b>กำหนดค่าการผลิต</b>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="reduceHeadText">ประเภทกระดาษ</label>
                  <select v-model="formData.materialSeq" class="select form-control" @change="selectMaterial">
                    <option value="" disabled selected hidden>ประเภทกระดาษ</option>
                    <option v-for="(item) in dropdownFormGetter.paperType" :key="item['seq']"
                            :value="item['seq']">{{
                        item['materialName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.materialSeq }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="reduceHeadText">ขนาดโมล</label>
                  <input v-model="formData.woMole" type="text" class="form-control"
                         placeholder="ขนาดโมล">
                  <span class="text-danger"></span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="reduceHeadText">ปรุฉีก</label>
                  <select v-model="formData.perforationCode" class="select form-control">
                    <option value="" disabled selected hidden>ปรุฉีก</option>
                    <option v-for="(item) in dropdownFormGetter.perforation" :key="item['perforationCode']"
                            :value="item['perforationCode']">{{
                        item['perforationName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.perforationCode }}</span>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group">
                  <label class="reduceHeadText">วานิช</label>
                  <select v-model="formData.varnishCode" class="select form-control">
                    <option value="" disabled selected hidden>วานิช</option>
                    <option v-for="(item) in dropdownFormGetter.vanish" :key="item['varnishCode']"
                            :value="item['varnishCode']">{{
                        item['varnishName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.varnishCode }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ขนาด (ต x น)</label>
                  <input
                      v-model="formData.woSizeV"
                      type="text"
                      class="form-control"
                      placeholder="ขนาด"
                      @input="handleInput($event,'woSizeV')"
                  >
                  <span class="text-danger">{{ validateResult.woSizeV }}{{
                      validatePreviewPrint.woSizeV
                    }}{{ validatePreviewSleet.woSizeV }}</span>
                </div>
              </div>
              <div class="align-self-end">
                <div class="form-group align-content-center">
                  <label class="reduceHeadText">X</label>
                </div>
              </div>
              <div class="col-sm-2 align-self-end">
                <div class="form-group">
                  <label class="reduceHeadText"></label>
                  <input
                      v-model="formData.woSizeH"
                      type="text"
                      class="form-control"
                      placeholder="ขนาด"
                      @input="handleInput($event,'woSizeH')"
                  >
                  <span class="text-danger">{{ validateResult.woSizeH }}</span>
                </div>
              </div>
              <div class="align-self-end" style="padding-right: 2%">
                <div class="form-group">
                  <label class="reduceHeadText">มม.</label>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ระยะรอบ</label>
                  <input
                      type="text"
                      disabled
                      v-model="formData.plRoundDistance"
                      class="form-control"
                      placeholder="ระยะรอบ"
                      @input="handleInput($event,'plRoundDistance')"
                  >
                  <span class="text-danger">{{ validateResult.plRoundDistance }}</span>
                </div>
              </div>
              <div class="col-sm-2" style="margin-left: -0.5%">
                <div class="form-group">
                  <label class="reduceHeadText">หน้ากว้างกระดาษ</label>
                  <input
                      type="text"
                      v-model="formData.plPaperWidth"
                      class="form-control"
                      placeholder="หน้ากว้างกระดาษ"
                      @input="handleInput($event,'plPaperWidth')"
                  >
                  <span class="text-danger">{{ validateResult.plPaperWidth }}{{
                      validatePreviewPrint.plPaperWidth
                    }}{{ validatePreviewSleet.plPaperWidth }}</span>
                </div>
              </div>
              <div class="align-self-end" style="margin-left: -0.5%; padding-right: 2%">
                <div class="form-group">
                  <label class="reduceHeadText">ซม.</label>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ไดคัท</label>
                  <select v-model="formData.diecutCode" class="select form-control" style="width: 150%">
                    <option value="" disabled selected hidden>ไดคัท</option>
                    <option v-for="(item) in dropdownFormGetter.diecut" :key="item['diecutCode']"
                            :value="item['diecutCode']">{{
                        item['diecutName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.diecutCode }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">วางแผนแล้ว</label>
                  <input v-model="formData.plPlanAhead" type="text" class="form-control"
                         placeholder="วางแผนแล้ว">
                  <span
                      class="text-danger">{{ validateResult.plPlanAhead }}{{
                      validatePreviewPrint.plPlanAhead
                    }}{{ validatePreviewSleet.plPlanAhead }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">จำนวนผลิตจริง</label>
                  <input v-model="formData.plProdAmount" type="text" class="form-control"
                         placeholder="จำนวนผลิตจริง">
                  <span class="text-danger">{{ validateResult.plProdAmount }}{{
                      validatePreviewPrint.plProdAmount
                    }}{{ validatePreviewSleet.plProdAmount }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">จำนวน</label>
                  <input
                      type="text"
                      disabled
                      v-model="formData.plAmount"
                      class="form-control"
                      placeholder="จำนวน"
                      @input="handleInput($event,'plAmount')"
                  >
                  <span class="text-danger">{{ validateResult.plAmount }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="reduceHeadText">จำนวนดวงพิมพ์ต่อแถว</label>
                  <input
                      type="text"
                      v-model="formData.plStartRowPrint"
                      class="form-control"
                      placeholder="จำนวนดวงพิมพ์ต่อแถว"
                      @input="handleInput($event,'plStartRowPrint')"
                  >
                  <span class="text-danger">{{ validateResult.plStartRowPrint }}{{
                      validatePreviewPrint.plStartRowPrint
                    }}{{ validatePreviewSleet.plStartRowPrint }}</span>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <label class="reduceHeadText">จำนวนบล็อคพิมพ์</label>
                  <input
                      type="text"
                      v-model="formData.plBlockAmount"
                      class="form-control"
                      placeholder="จำนวนบล็อคพิมพ์"
                      @input="handleInput($event,'plBlockAmount')"
                  >
                  <span class="text-danger">{{ validateResult.plBlockAmount }}{{
                      validatePreviewPrint.plBlockAmount
                    }}{{ validatePreviewSleet.plBlockAmount }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">จำนวนดวงต่อแถว</label>
                  <input
                      type="text"
                      v-model="formData.plStarRow"
                      class="form-control"
                      placeholder="จำนวนดวงต่อแถว"
                      @input="handleInput($event,'plStarRow')"
                  >
                  <span class="text-danger">{{ validateResult.plStarRow }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ขอบข้าง</label>
                  <input
                      type="text"
                      v-model="formData.plSide"
                      class="form-control"
                      placeholder="ขอบข้าง"
                      @input="handleInput($event,'plSide')"
                  >
                  <span class="text-danger">{{ validateResult.plSide }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">ช่องไฟ</label>
                  <input
                      type="text"
                      v-model="formData.plGab"
                      class="form-control"
                      placeholder="ช่องไฟ"
                      @input="handleInput($event,'plGab')"
                  >
                  <span
                      class="text-danger">{{ validateResult.plGab }}{{
                      validatePreviewPrint.plGab
                    }}{{ validatePreviewSleet.plGab }}</span>
                </div>
              </div>
              <div class="col-sm-4">
                <div class="form-group">
                  <label class="reduceHeadText">สี</label>
                  <select v-model="formData.colorCode" class="select form-control">
                    <option value="" disabled selected hidden>สี</option>
                    <option v-for="(item) in dropdownFormGetter.color" :key="item['colorCode']"
                            :value="item['colorCode']">{{
                        item['colorName']
                      }}
                    </option>
                  </select>
                  <span class="text-danger">{{ validateResult.colorCode }}</span>
                </div>
              </div>
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="reduceHeadText">หมายเหตุสี</label>
                  <input v-model="formData.plColorDescript" type="text" class="form-control"
                         placeholder="หมายเหตุสี">
                  <span class="text-danger"></span>
                </div>
              </div>
            </div>

            <ul class="nav nav-tabs" style="margin-bottom: 1%">
              <li class="nav-item">
                <button class="nav-link" :class="tabControl.print" @click.prevent="handleTabSelect(1)">เครื่องพิมพ์
                </button>
              </li>
              <li class="nav-item">
                <button class="nav-link" :class="tabControl.sleet" @click.prevent="handleTabSelect(2)">เครื่องสลีท
                </button>
              </li>
            </ul>

            <template v-if="tabControl.select === 1" class="row">
              <div class="row">
                <div class="col-sm-7">
                  <div class="form-group">
                    <label class="reduceHeadText">เครื่องจักร</label>
                    <select v-model="formData.machineSeq" @change="handleMachineSelect" class="select form-control">
                      <option value="" disabled selected hidden>เครื่องจักร</option>
                      <option v-for="(item) in printDropdown.machine" :key="item['seq']"
                              :value="item['seq']">{{
                          item['machineDetail']
                        }}
                      </option>
                    </select>
                    <span class="text-danger">{{
                        validateResult.machineSeq
                      }}{{ validatePreviewPrint.machineSeq }}</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาเครื่องวิ่ง (คำนวน)</label>
                    <input v-model="formData.plMachineRunning" type="text" disabled class="form-control"
                           placeholder="เวลาเครื่องวิ่ง">
                    <span class="text-danger"></span>
                  </div>
                </div>
                <div class="col-sm-1 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">ชม.</label>
                  </div>
                </div>
                <br>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาตั้งงาน</label>
                    <input
                        type="text"
                        v-model="formData.plStartTime"
                        class="form-control"
                        placeholder="เวลาตั้งงาน"
                        @input="handleInput($event,'plStartTime')"
                    >
                    <span
                        class="text-danger">{{ validateResult.plStartTime }}{{
                        validatePreviewPrint.plStartTime
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-1 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">ชม.</label>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="reduceHeadText">ความเร็ว(Stoke/Min)</label>
                    <input
                        type="text"
                        v-model="formData.plSpeedMin"
                        class="form-control"
                        placeholder="ความเร็ว"
                        @input="handleInput($event,'plSpeedMin')"
                    >
                    <span
                        class="text-danger">{{ validateResult.plSpeedMin }}{{ validatePreviewPrint.plSpeedMin }}</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาตัดม้วน</label>
                    <input
                        type="text"
                        v-model="formData.plRole"
                        class="form-control"
                        placeholder="เวลาตัดต่อม้วน"
                        @input="handleInput($event,'plRole')"
                    >
                    <span class="text-danger">{{ validateResult.plRole }}{{ validatePreviewPrint.plRole }}</span>
                  </div>
                </div>
                <div class="col-sm-1 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">ชม.</label>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <b>วัตถุดิบ</b>
              </div>
              <div class="row">
                <div class="col-sm-7">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="reduceHeadText">ราคาวัตถุดิบ(บาท/ตรม.)</label>
                        <input
                            type="text"
                            disabled
                            v-model="formData.plMaterialCost"
                            class="form-control"
                            placeholder="ราคาวัตถุดิบ"
                            @input="handleInput($event,'plMaterialCost')"
                        >
                        <span class="text-danger">{{ validateResult.plMaterialCost }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label class="reduceHeadText">ความยาววัตถุดิบที่ใช้(คำนวน)</label>
                        <input
                            type="text"
                            disabled
                            v-model="formData.plMaterialHeight"
                            class="form-control"
                            placeholder="ความยาววัตถุดิบที่ใช้"
                            @input="handleInput($event,'plMaterialHeight')"
                        >
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                    <div class="align-self-end">
                      <div class="form-group">
                        <label class="reduceHeadText">ม.</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="col-sm-12">
                    <div class="form-group">
                      <label class="reduceHeadText">หมายเหตุ</label>
                      <textarea v-model="formData.woRemark" style="padding-bottom: 20%" type="text"
                                class="form-control"
                                placeholder="หมายเหตุ"></textarea>
                      <span class="text-danger">{{}}</span>
                    </div>
                  </div>
                </div>
                <div class="col-sm-7" style="margin-top: -10%">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="reduceHeadText">ราคาหมึกพิมพ์</label>
                        <input
                            type="text"
                            v-model="formData.plInkCost"
                            class="form-control"
                            placeholder="ราคาหมึกพิมพ์"
                            @input="handleInput($event,'plInkCost')"
                        >
                        <span class="text-danger">{{ validateResult.plInkCost }}</span>
                      </div>
                    </div>
                    <div class="col-sm-5">
                      <div class="form-group">
                        <label class="reduceHeadText">วัตถุดิบที่ใช้</label>
                        <input
                            type="text"
                            disabled
                            v-model="formData.plMaterialCostCal"
                            class="form-control"
                            placeholder="วัตถุดิบที่ใช้"
                            @input="handleInput($event,'plMaterialCostCal')"
                        >
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                    <div class="align-self-end">
                      <div class="form-group">
                        <label class="reduceHeadText">ตร.ม.</label>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <b>ระยะเวลาการผลิต</b>
              </div>
              <div class="row">
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาเริ่มงาน(จริง)</label>
                    <input v-model="formData.plProdStart" type="datetime-local" class="form-control"
                           placeholder="">
                    <span
                        class="text-danger">{{ validateResult.plProdStart }}{{
                        validatePreviewPrint.plProdStart
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาปิดงาน(จริง)</label>
                    <input v-model="formData.plProdFinish" type="datetime-local" disabled class="form-control"
                           placeholder="ราคาวัตถุดิบ">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label class="reduceHeadText">กำหนดส่งงาน(จริง)</label>
                    <input v-model="formData.plProdDeliver" type="datetime-local" class="form-control"
                           placeholder="ราคาวัตถุดิบ">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="col-sm-12" style="margin-bottom:  -3%">
                  <label class="reduceHeadText">เวลาที่ใช้งานในการผลิต ชม. (ประมาณ)</label>
                </div>
                <div class="col-sm-2 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText"></label>
                    <input v-model="formData.plNormal" type="number" disabled class="form-control"
                           placeholder="0"
                           pattern="[0-9]*">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="align-self-end">
                  <div class="form-group align-content-center">
                    <label class="reduceHeadText">ปกติ</label>
                  </div>
                </div>
                <div class="col-sm-2 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText"></label>
                    <input v-model="formData.plOt" type="number" disabled class="form-control"
                           placeholder="0"
                           pattern="[0-9]*">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="align-self-end">
                  <div class="form-group align-content-center">
                    <label class="reduceHeadText">OT</label>
                  </div>
                </div>
                <div class="col-sm-2 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText"></label>
                    <input v-model="formData.plOtHoliday" disabled type="number" class="form-control"
                           placeholder="0"
                           pattern="[0-9]*">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="align-self-end">
                  <div class="form-group align-content-center">
                    <label class="reduceHeadText">OT อา,วันหยุด</label>
                  </div>
                </div>
                <div class="col-sm-2 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลารวม</label>
                    <input v-model="formData.plSumTime" disabled type="text" class="form-control"
                           placeholder="เวลารวม">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="col-sm-2 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาพัก</label>
                    <input v-model="formData.plBreakingTime" disabled type="text" class="form-control"
                           placeholder="เวลาพัก">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
              </div>
              <MachineCalendar :resourceData="machineCalendarResource" ref="calendar"/>
              <div class="col-sm-12 mt-5 mb-2 bg-blueSky">
                <div class="row align-items-center">
                  <div class="col-sm-3 text-left">
                    <div class="d-flex justify-content-start align-items-center">
                      <button class="btn btn-outline-info btn-sm bg-white-custom" @click.prevent="handlePreviousDate()">
                        <i class="fa fa-chevron-left"></i>
                      </button>
                      <button class="btn btn-outline-info btn-sm bg-white-custom" @click.prevent="handleNextDate()"><i
                          class="fa fa-chevron-right"></i>
                      </button>
                      <span class="ml-2">{{ handleTodayCheck() ? 'Today' : '' }}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 text-center"><b>{{ handleShowDate(dateNow) }}</b></div>
                  <div class="col-sm-3 text-right">
                    <button class="btn btn-primary btn-sm mb-2 mt-2">resource day</button>
                  </div>
                </div>
              </div>
              <div class="row ml-3">
                <div class="col-sm-6">
                  <div class="col-sm-6 align-self-end">
                    <div class="form-group">
                      <input @click="handleCheckAll('ot',$event)" v-model="checkAllOT.isChecked" type="checkbox"><b>
                      O.t. Time</b>
                      <span class="text-danger"></span>
                    </div>
                    <div v-for="(item,index) in printDropdown.ot" :key="item['seq']">
                      <div class="form-group" style="margin-top: -7%">
                        <input @click="handleCheckOTSelection(item['seq'],$event,'ot')"
                               v-model="checkedOT[index].isChecked" type="checkbox">
                        {{ convertDateShower(item['otStart']) }} - {{ convertDateShower(item['otFinish']) }}
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input @click="handleCheckAll('oth',$event)" v-model="checkAllOTHoliday.isChecked"
                               type="checkbox"><b> O.t. Time(อา.,วันหยุด)</b>
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                    <div class="col-sm-6" v-for="(item,index) in printDropdown.oth" :key="item['seq']">
                      <div class="form-group" style="margin-top: -7%">
                        <input @click="handleCheckOTSelection(item['seq'],$event,'oth')"
                               v-model="checkedOTHoliday[index].isChecked" type="checkbox">
                        {{ convertDateShower(item['otStart']) }} - {{ convertDateShower(item['otFinish']) }}
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style="margin-top: -0.5%">
              <div class="d-flex justify-content-center align-items-center mb-3">
                <button :disabled="isLoading" @click.prevent="handlePreview(1)" class="btn btn-primary custom-width">
                  พรีวิว
                </button>
              </div>
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <b>ตารางการผลิต</b>
              </div>
              <div class="table-responsive table-lm">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col" class="to-center">วันที่</th>
                    <th scope="col">เวลาเริ่ม</th>
                    <th scope="col">เวลาสิ้นสุด</th>
                    <th scope="col">ประเภทช่วงเวลา</th>
                    <th scope="col">ช่างพิมพ์</th>
                    <th scope="col">รอบพิมพ์</th>
                    <th scope="col">ความเร็ว</th>
                    <th scope="col">เพิ่มเติม</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- -->
                  <tr class="rowHovered" v-for="(item) in tblPreviewData.planningTimeLst" :key="item">
                    <td>{{ item['woplStartDate'] }}</td>
                    <td>{{ item['woplStartTime'] }}</td>
                    <td>{{ item['woplFinishTime'] }}</td>
                    <td>{{ getTimeType(item['woplTimeType']) }}</td>
                    <td>{{ item['employeeName'] }}</td>
                    <td>{{ item['woplRound'] }}</td>
                    <td>{{ item['woplSpeed'] }}</td>
                    <td>{{ item['woplDescription'] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>

            <template v-if="tabControl.select === 2" class="row">
              <div class="row">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="reduceHeadText">เครื่องสลีต</label>
                    <select class="select form-control" v-model="formData.machineSleetSeq">
                      <option value="" disabled selected hidden>เครื่องสลีต</option>
                      <option v-for="(item) in sleetDropdown.machine" :key="item['seq']"
                              :value="item['seq']">{{
                          item['machineDetail']
                        }}
                      </option>
                    </select>
                    <span
                        class="text-danger">{{ validateResult.machineSleetSeq }}{{
                        validatePreviewSleet.machineSleetSeq
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label class="reduceHeadText">ความเร็ว(Stoke/Min)</label>
                    <input
                        type="text"
                        v-model="formData.plSpeedMinSleet"
                        class="form-control"
                        placeholder="เวลาเครื่องวิ่ง"
                        @input="handleInput($event,'plSpeedMinSleet')"
                    >
                    <span
                        class="text-danger">{{ validateResult.plSpeedMinSleet }}{{
                        validatePreviewSleet.plSpeedMinSleet
                      }}</span>
                  </div>
                </div>
                <br>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาสลีต (คำนวน)</label>
                    <input type="text" v-model="formData.plSleetRunning" disabled class="form-control"
                           placeholder="เวลาตั้งงาน">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="col-sm-1 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">ชม.</label>
                  </div>
                </div>
                <div class="col-sm-5">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาสลีต (ประมาณ)</label>
                    <input
                        type="text"
                        v-model="formData.plSleetEstimation"
                        class="form-control"
                        placeholder="ความเร็ว"
                        @input="handleInput($event,'plSleetEstimation')"
                    >
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
                <div class="col-sm-1 align-self-end">
                  <div class="form-group">
                    <label class="reduceHeadText">ชม.</label>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาเริ่มงาน(จริง)</label>
                    <input v-model="formData.plSleetStart" type="datetime-local" class="form-control"
                           placeholder="ราคาวัตถุดิบ">
                    <span
                        class="text-danger">{{ validateResult.plSleetStart }}{{
                        validatePreviewSleet.plSleetStart
                      }}</span>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="reduceHeadText">เวลาปิดงาน(จริง)</label>
                    <input type="datetime-local" v-model="formData.plSleetFinish" disabled class="form-control"
                           placeholder="ราคาวัตถุดิบ">
                    <span class="text-danger">{{}}</span>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 mb-2 bg-blueSky">
                <div class="row align-items-center">
                  <div class="col-sm-3 text-left">
                    <div class="d-flex justify-content-start align-items-center">
                      <button class="btn btn-outline-info btn-sm bg-white-custom" @click.prevent="handlePreviousDate()">
                        <i class="fa fa-chevron-left"></i>
                      </button>
                      <button class="btn btn-outline-info btn-sm bg-white-custom" @click.prevent="handleNextDate()"><i
                          class="fa fa-chevron-right"></i>
                      </button>
                      <span class="ml-2">{{ handleTodayCheck() ? 'Today' : '' }}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 text-center"><b>{{ handleShowDate(dateNow) }}</b></div>
                  <div class="col-sm-3 text-right">
                    <button class="btn btn-primary btn-sm mb-2 mt-2">resource day</button>
                  </div>
                </div>
              </div>
              <div class="row ml-3">
                <div class="col-sm-6">
                  <div class="col-sm-6 align-self-end">
                    <div class="form-group">
                      <input @click="handleSleetCheckAll('ot',$event)" v-model="checkSleetAllOT.isChecked"
                             type="checkbox"><b>
                      O.t. Time</b>
                      <span class="text-danger"></span>
                    </div>
                    <div v-for="(item,index) in printDropdown.ot" :key="item['seq']">
                      <div class="form-group" style="margin-top: -7%">
                        <input @click="handleSleetCheckOTSelection(item['seq'],$event,'ot')"
                               v-model="checkedSleetOT[index].isChecked" type="checkbox">
                        {{ convertDateShower(item['otStart']) }} - {{ convertDateShower(item['otFinish']) }}
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="row">
                    <div class="col-sm-12">
                      <div class="form-group">
                        <input @click="handleSleetCheckAll('oth',$event)" v-model="checkSleetAllOTHoliday.isChecked"
                               type="checkbox"><b> O.t. Time(อา.,วันหยุด)</b>
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                    <div class="col-sm-6" v-for="(item,index) in printDropdown.oth" :key="item['seq']">
                      <div class="form-group" style="margin-top: -7%">
                        <input @click="handleSleetCheckOTSelection(item['seq'],$event,'oth')"
                               v-model="checkedSleetOTHoliday[index].isChecked" type="checkbox">
                        {{ convertDateShower(item['otStart']) }} - {{ convertDateShower(item['otFinish']) }}
                        <span class="text-danger">{{}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr style="margin-top: -0.5%">
              <div class="d-flex justify-content-center align-items-center mb-3">
                <button :disabled="isLoading" @click.prevent="handlePreview(2)" class="btn btn-primary custom-width">
                  พรีวิว
                </button>
              </div>
              <div class="col-sm-12 mb-2 bg-secondary bg-light">
                <b>ตารางการผลิต</b>
              </div>
              <div class="table-responsive table-lm">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col" class="to-center">เครื่องสลีต</th>
                    <th scope="col">จำนวนม้วน</th>
                    <th scope="col">ช่างสลีต</th>
                    <th scope="col">วันที่เริ่ม</th>
                    <th scope="col">เวลาเริ่ม</th>
                    <th scope="col">ถึงเวลา</th>
                  </tr>
                  </thead>
                  <tbody>
                  <!-- -->
                  <tr class="rowHovered" v-for="(item) in remapMachine" :key="item">
                    <td>{{ item['machineDetail'] }}</td>
                    <td>{{ item['wospSleetAmount'] }}</td>
                    <td>{{ item['employeeName'] }}</td>
                    <td>{{ item['wospStartDate'] }}</td>
                    <td>{{ item['wospStartTime'] }}</td>
                    <td>{{ item['wospFinishTime'] }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </template>

          </div>
          <div class="modal-footer" v-if="tabControl.select === 1">
            <button type="button" class="btn btn-default float-right" data-dismiss="modal">ยกเลิก</button>
            <button type="submit" class="btn btn-primary float-right"
                    :disabled="tblPreviewData.planningTimeLst.length === 0">บันทึก
            </button>
          </div>
          <div class="modal-footer" v-if="tabControl.select === 2">
            <button type="button" class="btn btn-default float-right" data-dismiss="modal">ยกเลิก</button>
            <button type="submit" class="btn btn-primary float-right"
                    :disabled="tblPreviewData.sleetTimeLst.length === 0">บันทึก
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import loginStore from "@/store/loginStore";
import masterStore from "@/store/MasterStore";
import * as Yup from "yup";
import productionListStore from "@/store/productionListStore";
import Loading from "@/components/loading/Loading.vue";
import FullCalender from "@/components/resourceCalendar/FullCalender.vue";
import MachineCalendar from "@/components/productionPlanList/machineCalendar.vue";
import machineStore from "@/store/machineStore";


export default {
  name: "ProductionPlanListModal",
  components: {MachineCalendar, FullCalender, Loading},

  props: ['item'],
  data() {
    try {
      return {
        isLoading: false,
        token: loginStore.getters.token,
        employeeSeq: loginStore.getters.seq,
        dateNow: new Date(),
        tblData: [],
        tblPreviewData: {
          planningTimeLst: [],
          sleetTimeLst: []
        },
        checkAllOT: {isChecked: false},
        checkSleetAllOT: {isChecked: false},
        checkedOT: [],
        checkedSleetOT: [],
        checkAllOTHoliday: {isChecked: false},
        checkSleetAllOTHoliday: {isChecked: false},
        checkedOTHoliday: [],
        checkedSleetOTHoliday: [],
        formData: {
          seq: null,
          plCode: '',
          type: '',
          materialSeq: null,
          woMole: "",
          perforationCode: "",
          varnishCode: "",
          woSizeH: null,
          woSizeV: null,
          plRoundDistance: null,
          plPaperWidth: null,
          diecutCode: "",
          plPlanAhead: null,
          plProdAmount: null,
          plAmount: null,
          plStartRowPrint: null,
          plStarRow: null,
          plBlockAmount: null,
          plSide: null,
          plGab: null,
          colorCode: null,
          plColorDescript: "",
          machineSeq: '',
          plMachineRunning: null,
          plStartTime: null,
          plSpeedMin: null,
          woRemark: "",
          plRole: null,
          plMaterialCost: null,
          plMaterialHeight: null,
          plInkCost: null,
          plMaterialCostCal: null,
          plProdStart: null,
          plProdFinish: null,
          plProdDeliver: null,
          plNormal: null,
          plOt: null,
          plOtHoliday: null,
          plSumTime: null,
          plBreakingTime: null,
          otNormal: [],
          otHoliday: [],
          machineSleetSeq: '',
          plSpeedMinSleet: null,
          plSleetRunning: null,
          plSleetEstimation: null,
          plSleetStart: null,
          plSleetFinish: null,
          otsNormal: [],
          otsHoliday: []
        },
        dropdownFormGetter: {
          paperType: [],
          perforation: [],
          vanish: [],
          diecut: [],
          color: []
        },
        printDropdown: {
          machine: [],
          ot: [],
          oth: []
        },
        sleetDropdown: {
          machine: [],
          ot: [],
          oth: []
        },
        wosCode: '',
        workOrderData: {
          woNumber: '',
          woName: '',
          ctmName: '',
          woWcode: '',
          wotName: '',
          woDeliverDate: '',
          empName: '',
          woRemark: ''
        },
        tabControl: {
          select: 1,
          print: "active-nav-plan",
          sleet: "nav-link-normal"
        },
        previewPayload: {
          woSeq: null,
          woNumber: '',
          wosCode: '',
          print: 'n',
          sleet: 'n',
          plProdStart: '',
          plStartTime: '',
          plPlanAhead: null,
          sleetStockMin: null,
          machineStockMin: null,
          plStartRowPrint: null,
          plSizeH: null,
          plPaperWidth: null,
          plProdAmount: null,
          plBlockAmount: null,
          plRole: null,
          measureCode: '',
          woStarRoll: null,
          ot: [],
          otH: [],
          otS: [],
          otHS: [],
          machinePrintseq: '',
          machineSleetSeq: ''
        },
        validateResult: {
          materialSeq: '',
          perforationCode: '',
          varnishCode: '',
          woSizeH: '',
          woSizeV: '',
          plRoundDistance: '',
          plPaperWidth: '',
          diecutCode: '',
          plPlanAhead: '',
          plProdAmount: '',
          plAmount: '',
          plStartRowPrint: '',
          plStarRow: '',
          plBlockAmount: '',
          plSide: '',
          plGab: '',
          colorCode: '',
          plColorDescript: '',
          machineSeq: '',
          woRemark: '',
          plMaterialCost: '',
          plInkCost: '',
          plProdStart: '',
          plProdDeliver: '',
          plSpeedMin: '',
          plStartTime: '',
          plRole: ''
        },
        validatePreviewPrint: {
          plProdStart: '',
          plGab: '',
          plPlanAhead: '',
          plSpeedMin: '',
          plStartRowPrint: '',
          woSizeH: '',
          plPaperWidth: '',
          plProdAmount: '',
          plBlockAmount: '',
          plRole: '',
          plStartTime: '',
          machineSeq: ''
        },
        validatePreviewSleet: {
          plPaperWidth: '',
          plPlanAhead: '',
          plProdAmount: '',
          plStartRowPrint: '',
          plBlockAmount: '',
          plGab: '',
          plSleetStart: '',
          plSpeedMinSleet: '',
          machineSleetSeq: ''
        },
        requireSleet: false,
        requirePrint: false,
        machineCodeSelection : null,
        machineSeqSelection: null,
        machineCalendarResource:{
          resource:[],
          events:[],
        }
      }
    } catch (e) {
      throw new Error(e);
    }
  },
  computed: {
    remapMachine() {
      return this.tblPreviewData.sleetTimeLst.map(item => {
        const dataMachine = this.sleetDropdown.machine.find(data => data.machineCode === item.machineCode);
        item.machineDetail = dataMachine.machineDetail
        return item;
      });
    },
    previewPrintSchema() {
      return Yup.object().shape({
        plPaperWidth: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plPlanAhead: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plProdAmount: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plStartRowPrint: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plBlockAmount: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plGab: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(0|[1-9]\d{0,2})(.\d{1,3})?$/, 'ตัวเลขเท่านั้น'),
        plProdStart: Yup.string().required('ห้ามเป็นค่าว่าง'),
        plSpeedMin: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        woSizeH: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plRole: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plStartTime: Yup.string().required('ห้ามเป็นค่าว่าง'),
        machineSeq: Yup.mixed().oneOf(this.printDropdown.machine.map(c => c.seq), 'ห้ามเป็นค่าว่าง').required('ห้ามเป็นค่าว่าง'),
      })
    },
    previewSleetSchema() {

      return Yup.object().shape({
        plPaperWidth: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plPlanAhead: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plProdAmount: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plStartRowPrint: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plBlockAmount: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        plGab: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plSleetStart: Yup.string().required('ห้ามเป็นค่าว่าง'),
        plSpeedMinSleet: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^(\d+|\d*\.\d{1,3})$/, 'ตัวเลขเท่านั้น'),
        machineSleetSeq: Yup.mixed().oneOf(this.sleetDropdown.machine.map(c => c.seq), 'ห้ามเป็นค่าว่าง').required('ห้ามเป็นค่าว่าง'),


      })
    },
    formSchema() {
      return Yup.object().shape({
        materialSeq: Yup.mixed().required('ต้องเลือกประเภทกระดาษ').oneOf(this.dropdownFormGetter.paperType.map(c => c.seq)),
        perforationCode: Yup.mixed().required('ต้องเลือกปรุฉีก').oneOf(this.dropdownFormGetter.perforation.map(c => c.perforationCode)),
        varnishCode: Yup.mixed().required().oneOf(this.dropdownFormGetter.vanish.map(c => c.varnishCode)),
        woSizeH: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        woSizeV: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plRoundDistance: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plPaperWidth: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        diecutCode: Yup.mixed().required('ต้องเลือกไดคัท').oneOf(this.dropdownFormGetter.diecut.map(c => c.diecutCode)),
        plPlanAhead: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plProdAmount: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plAmount: Yup.number(),
        plStartRowPrint: Yup.string().required().matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plStarRow: Yup.string().nullable().matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plBlockAmount: Yup.string().nullable().matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plSide: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plGab: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        colorCode: Yup.mixed().nullable().oneOf(this.dropdownFormGetter.color.map(c => c.colorCode)),
        plColorDescript: Yup.string().nullable(),
        machineSeq: Yup.mixed().required('ห้ามเป็นค่าว่าง').oneOf(this.printDropdown.machine.map(c => c.seq)),
        machineSleetSeq: Yup.mixed().required('ห้ามเป็นค่าว่าง').oneOf(this.sleetDropdown.machine.map(c => c.seq)),
        woRemark: Yup.string().nullable(),
        plMaterialCost: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plInkCost: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plProdStart: Yup.string().required('ห้ามเป็นค่าว่าง'),
        plProdDeliver: Yup.string().required('ห้ามเป็นค่าว่าง'),
        plSpeedMin: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
        plStartTime: Yup.string().required('ห้ามเป็นค่าว่าง'),
        plRole: Yup.string().required('ห้ามเป็นค่าว่าง').matches(/^\d+(\.\d{1,3})?$/, 'ต้องเป็นตัวเลขเท่านั้น'),
      })
    }
  },
  watch: {
    item: {
      async handler() {
        try {
          this.resetFormData()
          if (this.item) {
            if (this.item.type === 2) {
              this.wosCode = this.item.wosCode
              this.workOrderData.woNumber = this.item.woNumber
              this.workOrderData.woName = this.item.woName
              this.workOrderData.ctmName = this.item.ctmName
              this.workOrderData.woWcode = this.item.woWcode
              this.workOrderData.wotName = this.item.wotName
              this.workOrderData.woDeliverDate = this.item.woDeliverDate
              this.workOrderData.empName = this.item.empName
              this.workOrderData.woRemark = this.item.woRemark

              this.formData.type = this.item.type
              this.formData.seq = this.item['planning'] ? this.item['planning'].seq : ''
              this.formData.plCode = this.item['planning'] ? this.item['planning'].plCode : ''

              this.formData.materialSeq = this.item['planning'].mtSeq || this.item.planningRef.mtSeq ? this.item['planning'].mtSeq || this.item.planningRef.mtSeq : this.item.materialSeq ? this.item.materialSeq:''
              this.formData.woMole = this.item['planning'].plMole || this.item.planningRef.plMole ? this.item['planning'].plMole || this.item.planningRef.plMole : ''
              this.formData.perforationCode = this.item['planning'].perforationCode || this.item.planningRef.perforationCode ? this.item['planning'].perforationCode || this.item.planningRef.perforationCode : this.item.perforationCode ? this.item.perforationCode:''
              this.formData.varnishCode = this.item['planning'].varnishCode || this.item.planningRef.varnishCode ? this.item['planning'].varnishCode || this.item.planningRef.varnishCode : this.item.varnishCode ? this.item.varnishCode:''
              this.formData.woSizeV = this.item['planning'].plSizeV || this.item.planningRef.plSizeV ? this.item['planning'].plSizeV || this.item.planningRef.plSizeV : this.item.woSizeV ? this.item.woSizeV:''
              this.formData.woSizeH = this.item['planning'].plSizeH || this.item.planningRef.plSizeH ? this.item['planning'].plSizeH || this.item.planningRef.plSizeH : this.item.woSizeH ? this.item.woSizeH:''
              this.formData.plRoundDistance = this.item['planning'].plRoundDistance || this.item.planningRef.plRoundDistance ? this.item['planning'].plRoundDistance || this.item.planningRef.plRoundDistance : ''
              this.formData.plPaperWidth = this.item['planning'].plPaperWidth || this.item.planningRef.plPaperWidth ? this.item['planning'].plPaperWidth || this.item.planningRef.plPaperWidth : ''
              this.formData.diecutCode = this.item['planning'].diecutCode || this.item.planningRef.diecutCode ? this.item['planning'].diecutCode || this.item.planningRef.diecutCode :  this.item.diecutCode ? this.item.diecutCode:''
              this.formData.plPlanAhead = this.item['planning'].plPlanAhead || this.item.planningRef.plPlanAhead ? this.item['planning'].plPlanAhead || this.item.planningRef.plPlanAhead : 0
              this.formData.plProdAmount = this.item['planning'].plProdAmount || this.item.planningRef.plProdAmount ? this.item['planning'].plProdAmount || this.item.planningRef.plProdAmount : 0
              this.formData.plAmount = this.item['planning'].plAmount || this.item.planningRef.plAmount ? this.item['planning'].plAmount || this.item.planningRef.plAmount : this.item.woProdAmount ? this.item.woProdAmount:0
              this.formData.plStartRowPrint = this.item['planning'].plStartRowPrint || this.item.planningRef.plStartRowPrint ? this.item['planning'].plStartRowPrint || this.item.planningRef.plStartRowPrint : ''
              this.formData.plBlockAmount = this.item['planning'].plBlockAmount || this.item.planningRef.plBlockAmount ? this.item['planning'].plBlockAmount || this.item.planningRef.plBlockAmount : ''
              this.formData.plStarRow = this.item['planning'].plStarRow || this.item.planningRef.plStarRow ? this.item['planning'].plStarRow || this.item.planningRef.plStarRow : this.item.woStarRow ? this.item.woStarRow:0
              this.formData.plSide = this.item['planning'].plSide || this.item.planningRef.plSide ? this.item['planning'].plSide || this.item.planningRef.plSide :  this.item.woSide ? this.item.woSide:''
              this.formData.plGab = this.item['planning'].plGab || this.item.planningRef.plGab ? this.item['planning'].plGab || this.item.planningRef.plGab :  this.item.woGab ? this.item.woGab:''
              this.formData.colorCode = this.item['planning'].colorCode || this.item.planningRef.colorCode ? this.item['planning'].colorCode || this.item.planningRef.colorCode : ''
              this.formData.plColorDescript = this.item['planning'].plColorDescript || this.item.planningRef.plColorDescript ? this.item['planning'].plColorDescript || this.item.planningRef.plColorDescript : ''

              this.formData.machineSeq = this.item['planning'] ? this.item['planning'].machineSeq : ''

              //Case show calendar
              if (this.formData.machineSeq) {
                this.machineSeqSelection = this.formData.machineSeq
                this.machineCodeSelection = await this.mapMachineCode(this.formData.machineSeq)
                await this.handleCalendarData()
              }


              this.formData.plMachineRunning = this.item['planning'] ? this.item['planning'].plMachineRunning : ''
              this.formData.plStartTime = this.item['planning'] ? this.item['planning'].plStartTime : this.item.planningRef.plStartTime ? this.item.planningRef.plStartTime : ''
              this.formData.plSpeedMin = this.item['planning'] ? this.item['planning'].plSpeedMin : this.item.planningRef.plSpeedMin ? this.item.planningRef.plSpeedMin : ''
              this.formData.plRole = this.item['planning'] ? this.item['planning'].plRole : this.item.planningRef.plRole ? this.item.planningRef.plRole : ''
              this.formData.plMaterialCost = this.selectMaterial()
              this.formData.plMaterialHeight = this.item['planning'] ? this.item['planning'].plMaterialHeight : null
              this.formData.plInkCost = this.item['planning'] ? this.item['planning'].plInkCost : ''
              this.formData.plMaterialCostCal = this.item['planning'] ? this.item['planning'].plmaterialCostCal : null
              this.formData.plProdStart = this.item['planning'].plProdStart ? this.item['planning'].plProdStart.slice(0, 16) : ''
              this.formData.plProdFinish = this.item['planning'].plProdFinish ? this.item['planning'].plProdFinish.slice(0, 16) : ''
              this.formData.plProdDeliver = this.item['planning'].plProdDeliver ? this.item['planning'].plProdDeliver.slice(0, 16) : ''
              this.formData.plNormal = this.item['planning'] ? this.item['planning'].plNormal : ''
              this.formData.plOt = this.item['planning'] ? this.item['planning'].plOt : ''
              this.formData.plOtHoliday = this.item['planning'] ? this.item['planning'].plOtHoliday : ''
              this.formData.plBreakingTime = this.item['planning'] ? this.item['planning'].plBreakingTime : ''

              //Handle Summary time added breaking data
              const rawTimeData = this.item['planning'] ? parseFloat(this.item['planning'].plSumTime) : null
              this.formData.plSumTime = this.addTime(rawTimeData.toString(),this.formData.plBreakingTime.toString())

              // OT CheckBok setup
              if (this.item['planning']) {
                this.formData.otNormal = this.item['planning']['otNormal'] ? JSON.parse(this.item['planning']['otNormal']) : []
                this.formData.otHoliday = this.item['planning']['otHoliday'] ? JSON.parse(this.item['planning']['otHoliday']) : []
                this.formData.otsNormal = this.item['planning']['otsNormal'] ? JSON.parse(this.item['planning']['otsNormal']) : []
                this.formData.otsHoliday = this.item['planning']['otsHoliday'] ? JSON.parse(this.item['planning']['otsHoliday']) : []
              } else {
                const {otNormal, otHoliday, otsNormal, otsHoliday} = this.formData;
                [this.formData.otNormal, this.formData.otHoliday, this.formData.otsNormal, this.formData.otsHoliday] = [otNormal, otHoliday, otsNormal, otsHoliday].map(_ => []);
              }
              const origin = ['checkedOT', 'checkedOTHoliday', 'checkedSleetOT', 'checkedSleetOTHoliday']
              const destination = ['otNormal', 'otHoliday', 'otsNormal', 'otsHoliday']
              for (let data in origin) {
                this.handleSetUpOT([origin[data]], this[origin[data]], this.formData[destination[data]])
              }
              this.resetCheckedAllInput()
              this.resetSleetCheckedAllInput()

              this.formData.woRemark = this.item.woRemark

              this.formData.machineSleetSeq = this.item['planning'] ? this.item['planning'].machineSleetSeq : ''
              this.formData.plSpeedMinSleet = this.item['planning'] ? this.item['planning'].plSpeedMinSleet : this.item.planningRef.plSpeedMinSleet ? this.item.planningRef.plSpeedMinSleet : ''
              this.formData.plSleetRunning = this.item['planning'] ? this.item['planning'].plSleetRunning : ''
              this.formData.plSleetEstimation = this.item['planning'] ? this.item['planning'].plSleetEstimation : this.item.planningRef.plSleetEstimation ? this.item.planningRef.plSleetEstimation : ''
              this.formData.plSleetStart = this.item['planning'].plSleetStart ? this.item['planning'].plSleetStart.slice(0, 16) : ''
              this.formData.plSleetFinish = this.item['planning'].plSleetFinish ? this.item['planning'].plSleetFinish.slice(0, 16) : ''
              const tblPreviewResult = await this.getPreviewData({seq: this.item.seq, woNumber: this.item.woNumber})
              this.tblPreviewData.planningTimeLst = tblPreviewResult.planningLst.length > 0 ? tblPreviewResult.planningLst : []
              this.tblPreviewData.sleetTimeLst = tblPreviewResult.sleetLst.length > 0 ? tblPreviewResult.sleetLst : []
            }
          }
        } catch (e) {
          throw new Error(e);
        }
      },
      deep: true
    }
  },
  async mounted() {
    try {
      const payload = {
        token: this.token
      }
      await masterStore.dispatch('workOrderPlanningForm', payload)
      await masterStore.dispatch('workOrderDropdown', payload)
      this.getDropdownData()
      this.resetCheckedInput()
      this.resetSleetCheckedInput()
    } catch (e) {
      throw new Error(e)
    }
  },
  methods: {
    handleSetUpOT(detail, origin, destination) {
      for (let x = 0; x < destination.length; x++) {
        origin.filter(item => {
          if ((item.seq === destination[x].seq) && (item.day === destination[x].day)) item.isChecked = destination[x].isChecked
        })
      }
      this[detail] = origin
    },
    handleInput(event, formData) {
      const sanitizedValue = event.target.value.replace(/[^0-9.]/g, '');
      const match = sanitizedValue.match(/(\d*\.\d{0,3}|\d*)/);
      this.formData[formData] = match ? match[0] : '';
    },
    async getMaterialPrice() {
      return await masterStore.getters.workOrderDropdown['materialLst'];
    },
    async selectMaterial() {
      const selectValue = this.formData.materialSeq;
      const mtPrice = await this.getMaterialPrice();

      if (mtPrice) {
        let selectedMaterialPrice = null;

        mtPrice.forEach((item) => {
          if (selectValue === item.seq) {
            selectedMaterialPrice = item.materialPrice;
          }
        });
        this.formData.plMaterialCost = selectedMaterialPrice;
      } else {
        this.formData.plMaterialCost = null;
      }
      return this.formData.plMaterialCost;
    },
    async getPreviewData(data) {
      await productionListStore.dispatch('getPreviewData', {
        token: this.token,
        woSeq: data.seq,
        woNumber: data.woNumber
      })
      return {
        planningLst: productionListStore.getters.getPreviewData['planningLst'],
        sleetLst: productionListStore.getters.getPreviewData['sleetLst']
      }
    },
    getTimeType(timetype) {
      try {
        switch (timetype) {
          case "s":
            return 'ตั้งงาน';
          case "w":
            return 'ปกติ';
          case "f":
            return 'พัก';
          case "fot":
            return 'พัก - OT';
          case "ot":
            return 'OT';
          default:
            return '';
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    handleTodayCheck() {
      const date = new Date();
      const todayDate = this.handleShowDate(date)
      return this.handleShowDate(this.dateNow) === todayDate
    },
    handleCheckAll(type, event) {
      try {
        const isChecked = event.target.checked
        const otData = type === 'ot' ? this.checkedOT : this.checkedOTHoliday
        if (isChecked) {
          for (let x = 0; x < otData.length; x++) {
            const arrMatch = this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].filter(item => item.seq === otData[x]['seq'] && item.day === otData[x].day)
            if (arrMatch.length > 0) {
              this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].map(item => {
                if (item.seq === otData[x].seq && item.day === otData[x].day) {
                  item.isChecked = true
                }
              })
            } else {
              otData[x].isChecked = true
              this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].push(otData[x])
            }
          }
        } else {
          const validateData = this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`]
          for (let x = 0; x < otData.length; x++) {
            const arrMatch = this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].findIndex(item => item.seq === otData[x]['seq'] && item.day === otData[x].day)
            if (arrMatch >= 0) validateData.splice(arrMatch, 1)
            otData[x].isChecked = false
          }
          this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`] = validateData
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    handleSleetCheckAll(type, event) {
      try {
        const isChecked = event.target.checked
        const otData = type === 'ot' ? this.checkedSleetOT : this.checkedSleetOTHoliday
        if (isChecked) {
          for (let x = 0; x < otData.length; x++) {
            const arrMatch = this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].filter(item => item.seq === otData[x]['seq'] && item.day === otData[x].day)
            if (arrMatch.length > 0) {
              this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].map(item => {
                if (item.seq === otData[x].seq && item.day === otData[x].day) {
                  item.isChecked = true
                }
              })
            } else {
              otData[x].isChecked = true
              this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].push(otData[x])
            }
          }
        } else {
          const validateData = this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`]
          for (let x = 0; x < otData.length; x++) {
            const arrMatch = this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].findIndex(item => item.seq === otData[x]['seq'] && item.day === otData[x].day)
            if (arrMatch >= 0) validateData.splice(arrMatch, 1)
            otData[x].isChecked = false
          }
          this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`] = validateData
        }
      } catch (e) {
        throw new Error(e)
      }
    },
    resetCheckedAllInput() {
      try {
        const filterDataNormal = this.formData.otNormal.filter(item => item.day === this.handleDateFormat(this.dateNow))
        this.checkAllOT.isChecked = filterDataNormal.length === this.printDropdown.ot.length
        const filterDataHoliday = this.formData.otHoliday.filter(item => item.day === this.handleDateFormat(this.dateNow))
        this.checkAllOTHoliday.isChecked = filterDataHoliday.length === this.printDropdown.oth.length
      } catch (e) {
        throw new Error(e)
      }
    },
    resetSleetCheckedAllInput() {
      try {
        const filterDataNormal = this.formData.otsNormal.filter(item => item.day === this.handleDateFormat(this.dateNow))
        this.checkSleetAllOT.isChecked = filterDataNormal.length === this.printDropdown.ot.length
        const filterDataHoliday = this.formData.otsHoliday.filter(item => item.day === this.handleDateFormat(this.dateNow))
        this.checkSleetAllOTHoliday.isChecked = filterDataHoliday.length === this.printDropdown.oth.length
      } catch (e) {
        throw new Error(e)
      }
    },
    resetCheckedInput() {
      try {
        this.checkedOT = this.printDropdown.ot.map((item) => {
          const filterResult = this.formData.otNormal.length > 0 ? this.formData.otNormal.filter(data => data.seq === item.seq && data.day === this.handleDateFormat(this.dateNow)) : []
          const result = filterResult.length > 0 ? filterResult[0].isChecked : false
          return {day: this.handleDateFormat(this.dateNow), seq: item.seq, isChecked: result}
        })
        this.checkedOTHoliday = this.printDropdown.oth.map((item) => {
          const filterResult = this.formData.otHoliday.length > 0 ? this.formData.otHoliday.filter(data => data.seq === item.seq && data.day === this.handleDateFormat(this.dateNow)) : []
          const result = filterResult.length > 0 ? filterResult[0].isChecked : false
          return {day: this.handleDateFormat(this.dateNow), seq: item.seq, isChecked: result}
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    resetSleetCheckedInput() {
      try {
        this.checkedSleetOT = this.printDropdown.ot.map((item) => {
          const filterResult = this.formData.otsNormal.length > 0 ? this.formData.otsNormal.filter(data => data.seq === item.seq && data.day === this.handleDateFormat(this.dateNow)) : []
          const result = filterResult.length > 0 ? filterResult[0].isChecked : false
          return {day: this.handleDateFormat(this.dateNow), seq: item.seq, isChecked: result}
        })
        this.checkedSleetOTHoliday = this.printDropdown.oth.map((item) => {
          const filterResult = this.formData.otsHoliday.length > 0 ? this.formData.otsHoliday.filter(data => data.seq === item.seq && data.day === this.handleDateFormat(this.dateNow)) : []
          const result = filterResult.length > 0 ? filterResult[0].isChecked : false
          return {day: this.handleDateFormat(this.dateNow), seq: item.seq, isChecked: result}
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    handleShowDate(data) {
      try {
        const date = new Date(data);
        const options = {weekday: 'long', year: 'numeric', month: 'short', day: 'numeric'};
        return date.toLocaleDateString('en-US', options)
      } catch (e) {
        throw new Error(e)
      }
    },
    handleNextDate() {
      try {
        const newDate = new Date(this.dateNow);
        newDate.setDate(newDate.getDate() + 1);
        this.dateNow = newDate;
        this.resetCheckedInput()
        this.resetCheckedAllInput()
        this.resetSleetCheckedInput()
        this.resetSleetCheckedAllInput()
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    handlePreviousDate() {
      try {
        const newDate = new Date(this.dateNow);
        newDate.setDate(newDate.getDate() - 1);
        this.dateNow = newDate;
        this.resetCheckedInput()
        this.resetCheckedAllInput()
        this.resetSleetCheckedInput()
        this.resetSleetCheckedAllInput()
      } catch (e) {
        console.log(e)
        throw new Error(e)
      }
    },
    handleCheckOTSelection(data, event, type) {
      try {
        event.stopPropagation();
        const isChecked = event.target.checked
        const date = this.handleDateFormat(this.dateNow)
        const seq = data
        if (isChecked) {
          const validateArrayPush = this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].filter(item => item.seq === seq && item.day === date)
          if (validateArrayPush.length <= 0) this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`].push({
            day: date,
            seq,
            isChecked: true
          })
        } else {
          const validateData = this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`]
          const positionArr = validateData.findIndex(item => item.day === date && item.seq === seq)
          if (positionArr >= 0) validateData.splice(positionArr, 1)
          this.formData[`${type === 'ot' ? 'otNormal' : 'otHoliday'}`] = validateData
        }
        this.resetCheckedAllInput()
      } catch (e) {
        throw new Error(e)
      }
    },
    handleSleetCheckOTSelection(data, event, type) {
      try {
        event.stopPropagation();
        const isChecked = event.target.checked
        const date = this.handleDateFormat(this.dateNow)
        const seq = data
        if (isChecked) {
          const validateArrayPush = this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].filter(item => item.seq === seq && item.day === date)
          if (validateArrayPush.length <= 0) this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`].push({
            day: date,
            seq,
            isChecked: true
          })
        } else {
          const validateData = this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`]
          const positionArr = validateData.findIndex(item => item.day === date && item.seq === seq)
          if (positionArr >= 0) validateData.splice(positionArr, 1)
          this.formData[`${type === 'ot' ? 'otsNormal' : 'otsHoliday'}`] = validateData
        }
        this.resetSleetCheckedAllInput()
      } catch (e) {
        throw new Error(e)
      }
    },
    handleDateFormat(dataDate) {
      try {
        const date = new Date(dataDate);
        const year = date.getFullYear();
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const day = ("0" + date.getDate()).slice(-2);
        return year + "-" + month + "-" + day;
      } catch (e) {
        throw new Error(e)
      }
    },
    convertDateShower(date) {
      try {
        return date.slice(0, -3)
      } catch (e) {
        throw new Error(e)
      }
    },
    getDropdownData() {
      try {
        this.isLoading = true
        const dropdownForm = masterStore.getters.workOrderPlanningForm
        this.dropdownFormGetter.paperType = dropdownForm['materialLst']
        this.dropdownFormGetter.perforation = dropdownForm['perforationLst']
        this.dropdownFormGetter.vanish = dropdownForm['varnishLst']
        this.dropdownFormGetter.diecut = dropdownForm['diecutLst']
        this.dropdownFormGetter.color = dropdownForm['color']
        this.printDropdown.ot = dropdownForm['ot']['otTime']
        this.printDropdown.oth = dropdownForm['ot']['otWeekend']
        this.printDropdown.machine = dropdownForm['machineLst']
        this.sleetDropdown.ot = dropdownForm['ot']['otTime']
        this.sleetDropdown.oth = dropdownForm['ot']['otWeekend']
        this.sleetDropdown.machine = dropdownForm['machineSleetLst']
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        throw new Error(e)
      }
    },
    handleTabSelect(select) {
      try {
        this.resetDate();
        this.tabControl.select = select
        this.tabControl.print = select === 1 ? "active-nav-plan" : "nav-link-normal"
        this.tabControl.sleet = select === 2 ? "active-nav-plan" : "nav-link-normal"
      } catch (e) {
        throw new Error(e)
      }
    },
    resetDate() {
      this.dateNow = new Date();
    },
    transformDateformatToAPI(date) {
      return date + ":00.000Z"
    },
    convertDataToStringFlot(data) {
      const floatData = parseFloat(data);
      const formattedData = floatData.toFixed(2);
      return formattedData.toString();
    },
    async handlePreview(data) {
      try {
        this.previewPayload.woSeq = parseFloat(this.item.seq)
        this.previewPayload.woNumber = this.item.woNumber.toString()
        this.previewPayload.wosCode = this.item.wosCode.toString()
        this.previewPayload.plPlanAhead = this.formData.plPlanAhead !== 0 ? parseFloat(this.formData.plPlanAhead) : parseFloat(this.formData.plProdAmount)
        this.previewPayload.plPaperWidth = parseFloat(this.formData.plPaperWidth)
        this.previewPayload.plProdAmount = parseFloat(this.formData.plProdAmount)
        this.previewPayload.plBlockAmount = parseFloat(this.formData.plBlockAmount)
        this.previewPayload.measureCode = this.item.measureCode.toString()
        this.previewPayload.woStarRoll = parseFloat(this.item.woStartAmountUnit)  // ดวงต่อม้วนและดวงต่อแผ่น แต่ยังเข้า parameter เดิม
        this.previewPayload.plSizeV = parseFloat(this.formData.woSizeV)
        this.previewPayload.plStartRowPrint = parseFloat(this.formData.plStartRowPrint)
        this.previewPayload.plGab = parseFloat(this.formData.plGab)
        //this.previewPayload.employeeSeq = this.employeeSeq
        const backupPreviewPrint = this.tblPreviewData.planningTimeLst;
        const backupPreviewSleet = this.tblPreviewData.sleetTimeLst;

        if (data === 1) {
          await this.previewPrintSchema.validate(this.formData, {abortEarly: false})
          this.previewPayload.print = data === 1 ? "y" : "n"
          this.previewPayload.sleet = data === 2 ? "y" : "n"
          this.previewPayload.plProdStart = this.transformDateformatToAPI(this.formData.plProdStart)
          this.previewPayload.machineStockMin = parseFloat(this.formData.plSpeedMin)
          this.previewPayload.plRole = parseFloat(this.formData.plRole)
          this.previewPayload.plStartTime = this.convertDataToStringFlot(this.formData.plStartTime)
          this.previewPayload.ot = this.formData.otNormal
          this.previewPayload.otH = this.formData.otHoliday
          this.previewPayload.calculateCost = "y"
          this.previewPayload.machinePrintseq = this.formData.machineSeq

        } else {
          await this.previewSleetSchema.validate(this.formData, {abortEarly: false})
          this.previewPayload.otS = this.formData.otsNormal
          this.previewPayload.otHS = this.formData.otsHoliday
          this.previewPayload.print = data === 1 ? "y" : "n"
          this.previewPayload.sleet = data === 2 ? "y" : "n"
          this.previewPayload.sleetStockMin = parseFloat(this.formData.plSpeedMinSleet)
          this.previewPayload.plsProdStart = this.transformDateformatToAPI(this.formData.plSleetStart)
          this.previewPayload.plGab = this.formData.plGab
          this.previewPayload.machineSleetSeq = this.formData.machineSleetSeq
        }

        this.isLoading = true
        await productionListStore.dispatch('previewData', {token: this.token, payload: this.previewPayload})
        const result = productionListStore.getters.previewData

        if (data === 1) {
          this.formData.plMachineRunning = result.plMachineRunning
          this.formData.plProdFinish = this.customFormattedDateTime(result.print['finishDate'])
          this.formData.plRoundDistance = result['roundDistance']
          this.formData.plMaterialHeight = result['materialLength']
          this.formData.plMaterialCostCal = result['rawMaterialsUsed']
          this.formData.plNormal = result.print['workingTime']
          this.formData.plOt = result.print['otTime']
          this.formData.plOtHoliday = result.print['otHolidayTime']
          this.formData.plBreakingTime = result.print['freeTime']
          const first = this.addTime(this.formData.plNormal, this.formData.plOt)
          const second = this.addTime(first, this.formData.plOtHoliday)
          const addBreakTime = this.addTime(second,this.formData.plBreakingTime)

          this.formData.plSumTime = parseFloat(addBreakTime).toFixed(2)
        } else {
          this.formData.plSleetRunning = result.plSleetRunning
          this.formData.plSleetFinish = this.customFormattedDateTime(result.sleet['finishDate'])
        }
        if (result) {
          await productionListStore.dispatch('getPreviewDataTmp', {
            token: this.token,
            woSeq: this.previewPayload.woSeq,
            woNumber: this.previewPayload.woNumber
          })

          if (data === 1) {
            this.tblPreviewData.planningTimeLst = productionListStore.getters.getPreviewDataTmp.planningTimeLst
            if (this.tblPreviewData.planningTimeLst.length <= 0) {
              this.tblPreviewData.planningTimeLst = backupPreviewPrint;
            }
          } else {
            this.tblPreviewData.sleetTimeLst = productionListStore.getters.getPreviewDataTmp.sleetTimeLst
            if (this.tblPreviewData.sleetTimeLst.length <= 0) {
              this.tblPreviewData.sleetTimeLst = backupPreviewSleet;
            }
          }
        }
        this.isLoading = false
      } catch (err) {
        this.isLoading = false
        if (data === 1) {
          this.validatePreviewPrint = {};
          err.inner.forEach((error) => {
            this.alertFunction();
            this.validatePreviewPrint[error.path] = error.message;
          });
        } else {
          this.validatePreviewSleet = {};
          err.inner.forEach((error) => {
            this.alertFunction();
            this.validatePreviewSleet[error.path] = error.message;
          });
        }
      }
    },
    addTime(time1, time2) {

      const [hours1, minutes1] = time1.split('.').map(Number);
      const [hours2, minutes2] = time2.split('.').map(Number);

      const totalMinutes = (hours1 * 60 + minutes1) + (hours2 * 60 + minutes2);

      let resultHours = Math.floor(totalMinutes / 60);
      let resultMinutes = totalMinutes % 60;

      if (resultMinutes < 0) {
        resultMinutes += 60;
        resultHours -= 1;
      } else if (resultMinutes >= 60) {
        resultMinutes -= 60;
        resultHours += 1;
      }

      return `${resultHours}.${resultMinutes.toString().padStart(2, '0')}`;
    },
    customFormattedDateTime(date) {
      const _toString = date.toString()
      const year = _toString.slice(0, 4)
      const month = _toString.slice(5, 7)
      const day = _toString.slice(8, 10)
      const hour = _toString.slice(11, 13)
      const minute = _toString.slice(14, 16)
      return `${year}-${month}-${day}T${hour}:${minute}`
    },
    handleModal() {
      try {
        const modalElement = $(this.$refs.productionPlanListModal);

        modalElement.modal({
          backdrop: 'static',
          keyboard: false
        });

        modalElement.on('shown.bs.modal', () => {
          this.$refs.calendar?.refreshCalendar(); // Trigger the refresh method
        });
      } catch (e) {
        throw new Error(e);
      }
    },
    async submitForm() {
      try {
        await this.formSchema.validate(this.formData, {abortEarly: false})
        this.isLoading = true;
        const token = this.token
        let payload = {}
        if (this.formData.type === 2) {
          if (this.wosCode === '003') {
            payload = {
              woSeq: parseFloat(this.item.seq),
              woNumber: this.item.woNumber,
              mtSeq: this.formData.materialSeq,
              plMole: this.formData.woMole,
              perforationCode: this.formData.perforationCode,
              varnishCode: this.formData.varnishCode,
              plSizeV: this.formData.woSizeV,
              plSizeH: this.formData.woSizeH,
              plRoundDistance: parseFloat(this.formData.plRoundDistance),
              plPaperWidth: parseFloat(this.formData.plPaperWidth),
              diecutCode: this.formData.diecutCode,
              plPlanAhead: parseFloat(this.formData.plPlanAhead),
              plProdAmount: parseFloat(this.formData.plProdAmount),
              plAmount: parseFloat(this.formData.plAmount),
              plStartRowPrint: parseFloat(this.formData.plStartRowPrint),
              plStarRow: parseFloat(this.formData.plStarRow),
              plBlockAmount: parseFloat(this.formData.plBlockAmount),
              plSide: parseFloat(this.formData.plSide),
              plGab: parseFloat(this.formData.plGab),
              colorCode: this.formData.colorCode,
              plColorDescript: this.formData.plColorDescript,
              machineSeq: this.formData.machineSeq,
              plMachineRunning: this.formData.plMachineRunning,
              plStartTime: this.formData.plStartTime,
              plSpeedMin: parseFloat(this.formData.plSpeedMin),
              plRole: this.formData.plRole,
              machineSleetSeq: this.formData.machineSleetSeq,
              plSpeedMinSleet: parseFloat(this.formData.plSpeedMinSleet),
              plSleetRunning: this.formData.plSleetRunning,
              plSleetEstimation: this.formData.plSleetEstimation,
              plSleetStart: this.formData.plSleetStart,
              plSleetFinish: this.formData.plSleetFinish,
              plMaterialCost: parseFloat(this.formData.plMaterialCost),
              plMaterialHeight: parseFloat(this.formData.plMaterialHeight),
              plInkCost: parseFloat(this.formData.plInkCost),
              plmaterialCostCal: parseFloat(this.formData.plMaterialCostCal),
              plProdStart: this.formData.plProdStart,
              plProdFinish: this.formData.plProdFinish,
              plProdDeliver: this.formData.plProdDeliver,
              plNormal: parseFloat(this.formData.plNormal),
              plOt: parseFloat(this.formData.plOt),
              plSumTime: parseFloat(this.formData.plSumTime).toFixed(2),
              plBreakingTime: this.formData.plBreakingTime,
              plOtHoliday: this.formData.plOtHoliday,
              status: "1",
              woRemark: this.formData.woRemark,
              otNormalLst: this.formData.otNormal,
              otHolidayLst: this.formData.otHoliday,
              otsNormalLst: [],
              otsHolidayLst: [],
              employeeSeq: this.employeeSeq
            }
            await productionListStore.dispatch('createData', {token, payload})
          } else {
            payload = {
              seq: this.formData.seq,
              plCode: this.formData.plCode,
              woSeq: parseFloat(this.item.seq),
              woNumber: this.item.woNumber,
              mtSeq: this.formData.materialSeq,
              plMole: this.formData.woMole,
              perforationCode: this.formData.perforationCode,
              varnishCode: this.formData.varnishCode,
              plSizeV: this.formData.woSizeV,
              plSizeH: this.formData.woSizeH,
              plRoundDistance: parseFloat(this.formData.plRoundDistance),
              plPaperWidth: parseFloat(this.formData.plPaperWidth),
              diecutCode: this.formData.diecutCode,
              plPlanAhead: parseFloat(this.formData.plPlanAhead),
              plProdAmount: parseFloat(this.formData.plProdAmount),
              plAmount: parseFloat(this.formData.plAmount),
              plStartRowPrint: parseFloat(this.formData.plStartRowPrint),
              plStarRow: parseFloat(this.formData.plStarRow),
              plBlockAmount: parseFloat(this.formData.plBlockAmount),
              plSide: parseFloat(this.formData.plSide),
              plGab: parseFloat(this.formData.plGab),
              colorCode: this.formData.colorCode,
              plColorDescript: this.formData.plColorDescript,
              machineSeq: this.formData.machineSeq,
              plMachineRunning: this.formData.plMachineRunning,
              plStartTime: this.formData.plStartTime,
              plSpeedMin: parseFloat(this.formData.plSpeedMin),
              plRole: this.formData.plRole,
              machineSleetSeq: this.formData.machineSleetSeq,
              plSpeedMinSleet: parseFloat(this.formData.plSpeedMinSleet),
              plSleetRunning: this.formData.plSleetRunning,
              plSleetEstimation: this.formData.plSleetEstimation,
              plSleetStart: this.formData.plSleetStart,
              plSleetFinish: this.formData.plSleetFinish,
              plMaterialCost: parseFloat(this.formData.plMaterialCost),
              plMaterialHeight: parseFloat(this.formData.plMaterialHeight),
              plInkCost: parseFloat(this.formData.plInkCost),
              plmaterialCostCal: parseFloat(this.formData.plMaterialCostCal),
              plProdStart: this.formData.plProdStart,
              plProdFinish: this.formData.plProdFinish,
              plProdDeliver: this.formData.plProdDeliver,
              plNormal: parseFloat(this.formData.plNormal),
              plOt: parseFloat(this.formData.plOt),
              plSumTime: parseFloat(this.formData.plSumTime).toFixed(2),
              plBreakingTime: this.formData.plBreakingTime,
              plOtHoliday: this.formData.plOtHoliday,
              status: "1",
              woRemark: this.formData.woRemark,
              otNormalLst: this.formData.otNormal,
              otHolidayLst: this.formData.otHoliday,
              otsNormalLst: [],
              otsHolidayLst: [],
              employeeSeq: this.employeeSeq
            }
            await productionListStore.dispatch('updateData', {token, payload})
          }
          const result = productionListStore.getters.saveResult
          this.isLoading = false;
          if (result) {
            this.successFunction()
          } else {
            this.errorFunction()
          }

        }
      } catch (err) {
        this.validateResult = {};
        err.inner.forEach((error) => {
          this.alertFunction();
          this.validateResult[error.path] = error.message;
        });
      }
    },
    alertFunction() {
      try {
        this.$swal.fire({
          title: 'กรุณากรอกข้อมูลให้ครบ',
          text: 'กรอกข้อมูลที่ในช่องระบุเป็นข้อมูลห้ามเป็นค่าว่างหรือจะต้องถูกต้องตามรูปแบบ',
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    successFunction() {
      try {
        this.$swal.fire({
          title: 'บันทึกข้อมูลสำเร็จ',
          text: 'ข้อมูลถูกบันทึกเรียบร้อยแล้ว',
          icon: 'success',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          $(this.$refs.productionPlanListModal).modal('hide');
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    errorFunction() {
      try {
        const messageError = productionListStore.getters.handleMessage
        this.$swal.fire({
          title: 'บันทึกข้อมูลล้มเหลว',
          text: messageError,
          icon: 'error',
          confirmButtonText: 'ยืนยัน'
        }).then(() => {
          this.$emit('form-submitted')
        })
      } catch (e) {
        throw new Error(e);
      }
    },
    async doDispatch() {
      try {
        this.isLoading = true
        const machinePayload = {
          token: this.token,
          page: 1,
          machineCode: this.machineCodeSelection,
          machineStatus: '',
          msCode: '',
          mtSeq: '',
          limit: 1000
        };
        const resourceViewPayload = {
          token: this.token,
          machineCode: this.machineCodeSelection,

        };
        await machineStore.dispatch('getDataTbl', machinePayload);
        await productionListStore.dispatch('getResourceView', resourceViewPayload);
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.error('Error loading machine data:', error);
      }
    },
    async getMachine() {
      try {
        const machineData = machineStore.getters.tblData;
        this.machineDropdown = machineData['machines']
        return machineData['machines'].map(item => ({
          id: item.seq,
          title: item.machineDetail,
          MachineType: item.mtName
        }));

      } catch (error) {
        console.error('Error loading machine data:', error);
      }
    },
    async getEvent() {
      try {
        const planningData = productionListStore.getters.getResourceData
        const reMapDataSeq = planningData.find(item => item['machineSeq'] === this.machineSeqSelection)
        const resultArray = reMapDataSeq ? [reMapDataSeq] : [];
        return resultArray.map(item => ({
          title: item['woWCode'],
          woSeq: item['woSeq'],
          detail: `${item['woWCode']}
          เริ่ม : ${item['startDate']}
          สิ้นสุด : ${item['finishDate']}`,
          start: new Date(item['startDate']),
          end: new Date(item['finishDate']),
          resourceId: item['machineSeq'],
          color: item['print'] !== undefined ? '#007BFF' : '#FF991F'
        }));
      } catch (error) {
        console.error('Error loading machine data:', error);
      }
    },
   async handleMachineSelect(event){
      const selectedMachineSeq = event.target.value;
      const filteredItems = this.printDropdown.machine.filter(item => item.seq === parseFloat(selectedMachineSeq));
      const { machineCode } = filteredItems[0];
      this.machineCodeSelection = machineCode;
      this.machineSeqSelection = parseFloat(selectedMachineSeq);
      await this.handleCalendarData()
    },
    async mapMachineCode(selectedMachineSeq){
      const filteredItems = this.printDropdown.machine.filter(item => item.seq === parseFloat(selectedMachineSeq));
      const  { machineCode } = filteredItems[0];
      return  machineCode
    },
    async handleCalendarData(){
      await this.doDispatch()
      const machineData = await this.getMachine()
      const eventData = await this.getEvent()
      this.machineCalendarResource.resource = machineData
      this.machineCalendarResource.events = eventData
    },
    resetFormData() {
      try {
        this.validateResult = {
          materialSeq: '',
          perforationCode: '',
          varnishCode: '',
          woSizeH: '',
          woSizeV: '',
          plRoundDistance: '',
          plPaperWidth: '',
          diecutCode: '',
          plPlanAhead: '',
          plProdAmount: '',
          plAmount: '',
          plStartRowPrint: '',
          plStarRow: '',
          plBlockAmount: '',
          plSide: '',
          plGab: '',
          colorCode: '',
          plColorDescript: '',
          machineSeq: '',
          woRemark: '',
          plMaterialCost: '',
          plInkCost: '',
          plProdStart: '',
          plProdDeliver: '',
          plSpeedMin: '',
          plStartTime: '',
          plRole: ''
        }
        this.validatePreviewPrint = {
          plProdStart: '',
          plGab: '',
          plPlanAhead: '',
          plSpeedMin: '',
          plStartRowPrint: '',
          woSizeH: '',
          plPaperWidth: '',
          plProdAmount: '',
          plBlockAmount: '',
          plRole: '',
          plStartTime: '',
          machineSeq: ''
        }
        this.validatePreviewSleet = {
          plPaperWidth: '',
          plPlanAhead: '',
          plProdAmount: '',
          plStartRowPrint: '',
          plBlockAmount: '',
          plGab: '',
          plSleetStart: '',
          plSpeedMinSleet: '',
          machineSleetSeq: ''
        }
      } catch (e) {

      }
    }
  }
}
</script>

<style scoped>

.text-danger {
  font-size: 14px;
}

.front-color-custom {
  color: #007BFF;
}

.custom-width {
  width: 15%
}
</style>